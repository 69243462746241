import axios from 'axios';

import authHeader from "@/services/auth-header";
import {useCamera} from "@/services/cameraService";

const API_URL = process.env.VUE_APP_API_URL;

class GroupServices{

    getGroup(id){

        const group = id?id:0;

        return  axios.get(API_URL + 'groups/'+group, { headers: authHeader() })
            .then( res => {
                    return res.data;
                }
            ).catch(err => console.log(err));
    }

    getAllGroups(){
        return axios.get( API_URL+'v1/groups', {headers: authHeader()})
            .then( res => {
                return res.data;
            })
            .catch( err => console.log(err));
    }

    joinGroup(join){
        return axios.post(API_URL+'groups/members', join,{headers: authHeader()});
    }

    joinRequest(join){
        return axios.post(API_URL+'groups/requests', join,{headers: authHeader()});
    }

    createGroup(group){
        return axios.post(API_URL+'groups/', group,{headers: authHeader()});
    }
    update(group){
        return axios.patch(API_URL+'groups/'+group.id, group, {headers:authHeader()});
    }

    takePhoto(group){
        return  useCamera().takePicture().then( img => {
            const header = { ...authHeader(), 'Content-Type': 'multipart/form-data'};
            const formData = new FormData();

            formData.append('file', img, `profile-img.jpg`);

            return axios.post(API_URL+'groups/'+group.id+'/images/upload', formData,{headers: header});

        })
    }

}
export  default new GroupServices();