import axios from 'axios';
import authHeader from './auth-header';
import {useCamera} from "@/services/cameraService";


const API_URL = process.env.VUE_APP_API_URL;


class ProfileService {

    getProfile(profileId){
         return  axios.get(API_URL + 'profile/'+profileId, { headers: authHeader() })
               .then( res => {
                       return res.data;
                   }
               ).catch(err => console.log(err));
        }

    updateAccount(profile){
        return axios.post(API_URL+'profile', profile,
            {headers: authHeader()})
            .then( res =>{
                return res.data;
            }).catch(err => console.log(err));
    }


    takePhoto(){
       return  useCamera().takePicture().then( img => {
            const header = { ...authHeader(), 'Content-Type': 'multipart/form-data'};
            const formData = new FormData();

            formData.append('file', img, `profile-img.jpg`);

            return axios.post(API_URL+'profile/image/upload', formData,
                {headers: header})
                .then( res =>{
                    return res.data;
                }).catch(err => console.log(err));

        })
    }

    // uploadProfileImage(image ){
    //
    //    const header = { ...authHeader(), 'Content-Type': 'multipart/form-data'};
    //
    //     const formData = new FormData();
    //     formData.append('file', image, `profile-img.jpg`);
    //
    //     return axios.post(API_URL+'profile/image/upload', formData,
    //         {headers: header})
    //         .then( res =>{
    //             return res.data;
    //         }).catch(err => console.log(err));
    // }

    getAccount(){
        return axios.get(API_URL+'profile',{ headers: authHeader() }).then(res => {
                const data= res.data;
                if(data){
                    localStorage.setItem('user', JSON.stringify(data));
                }
                return data;
            }
        ).catch(err => {
            // console.log(err);
            //if error here log user out
            return {logout: true};



        });
    }



}

export default new ProfileService();