import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '../views/Tabs.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path:'/login',
    component: () => import('@/views/Login.vue')
  },
  {
    path:'/register',
    component: () => import('@/views/Registration.vue')
  },
  {
    path:'/reset',
    component: () => import('@/views/Reset.vue')
  },
  {
    path:'/forgot',
    component: () => import('@/views/Forgot.vue')
  },
  {
    path:'/activate',
    component: () => import('@/views/Activate.vue')
  },

  {
    path: '/',
    component: Tabs,
    children: [
      {
        path: '/home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: '',
        redirect: '/home'
      },
      {
        path: '/calendar',
        component: () => import('@/views/Calendar.vue')
      },
      {
        path: '/profile/:id?',
        component: () => import('@/views/Profile.vue'),

      },
      {
        path: '/groups',
        component: () => import('@/views/Groups.vue')
      },
      {
        path: '/groups/:id',
        component: () => import('@/views/Group.vue'),
        props: true
      },
      {
        path: '/groups/find',
        component: () => import('@/views/FindGroup.vue')
      },
      {
        path: '/groups/create/',
        component: () => import('@/views/CreateGroup.vue')
      },
      {
        path: '/activity/:id',
        component: () => import('@/views/Activity.vue')
      },
      {
        path: '/activity/find',
        component: () => import('@/views/FindActivity.vue')
      },
      {
        path: '/activity/create/:groupId?',
        component: () => import('@/views/CreateActivity.vue')
      },
      {
        path: '/events',
        component: () => import('@/views/FindEvent.vue')
      },
      {
        path: '/event/:id',
        component: () => import('@/views/Event.vue')
      },

    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register','/reset', '/forgot', '/activate','/well-known/*','/.well-known/*'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router
