import axios from 'axios';

import authHeader from "@/services/auth-header";
import {useCamera} from "@/services/cameraService";

const API_URL = process.env.VUE_APP_API_URL;

class ActivityServices{

    getActivity(id){
        const activityId = id?id:0;
        return  axios.get(API_URL + 'activities/'+activityId, { headers: authHeader() })
            .then( res => {
                    return res.data;
                }
            ).catch(err => console.log(err));
    }
    joinActivity(join){
        return axios.post(API_URL+'activities/'+join.participationId+'/participants', join,
            {headers: authHeader()})
                .then( res =>{
                    return res.data;
                }).catch(err => console.log(err));
    }
    findAvailableActivities(){
        return  axios.get(API_URL + 'activities/available', { headers: authHeader() })
            .then( res => {
                    return res.data;
                }
            ).catch(err => console.log(err));
    }
    createActivity(activity){
        activity.startTime =  activity.startTime = new Date(activity.created+'T'+activity.startTime);
        return axios.post(API_URL+'activities', activity,{headers: authHeader()});
    }
    updateActivity(activity){
        return axios.patch(API_URL+'activities/'+activity.id, this.formatActivity(activity),{headers: authHeader()});
    }
    deleteActivity(activity){
        return axios.delete(API_URL+'activities/'+activity.id, { headers: authHeader() });
    }
    formatActivity(activity){
        activity.startTime = new Date(activity.created+'T'+activity.startTime);
        return activity;
    }
    takePhoto(activityDetails){
        return  useCamera().takePicture().then( img => {
            const header = { ...authHeader(), 'Content-Type': 'multipart/form-data'};
            const formData = new FormData();

            formData.append('file', img, `profile-img.jpg`);

            return axios.post(API_URL+'activities/'+activityDetails.id+'/images/upload', formData,{headers: header});

        })
    }
}
export  default new ActivityServices();