import EventServices from "@/services/event.services";

export const event = {

    state(){
        return{
            event:{}
        };
    },
    actions:{
        event(context, eventId){
            return EventServices.getEvent(eventId).then(
                event =>{
                    context.commit('event', event);
                    return Promise.resolve(event);
                },
                error =>{
                    return Promise.reject(error);
                }
            )
        },
        // getAllEvents(context){
        //         return EventServices.getAllEvent().then(
        //             res =>{
        //                     context.commit("events", res.data);
        //                     return Promise.resolve(res.data);
        //             },
        //             err =>{
        //                 return Promise.reject(err);
        //             }
        //         )
        // },
        joinEvent(context, join){
            return EventServices.joinEvent(join).then(
                event => {
                    // console.log(join);
                    context.commit('event',event);

                    context.dispatch('account');

                    return Promise.resolve(event);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        }

    },
    mutations:{
        event(state, event){
            state.event = event;
        },
        events(state, events){
            state.events =events;
        },
    },
    getters:{
        event(state){
            return state.event;
        },
        events(state){
            return state.events;
        },
        joinedEvent(_,getters){
            return getters.event.joined;
        }
    }
}