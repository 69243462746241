import GroupServices from "@/services/group.services";
import groupServices from "@/services/group.services";

export const group = {
    namespaced: true,
    state() {
        return {
            group: {},
        };
    },
    actions: {
        group(context, groupId) {
            return GroupServices.getGroup(groupId).then(
                group => {
                    context.commit('group', group);
                    return Promise.resolve(group);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        getAllGroups(context) {
            return GroupServices.getAllGroups().then(
                groups => {
                    context.commit("groups", groups);
                    return Promise.resolve(groups);
                },
                err => {
                    return Promise.reject(err);
                }
            )
        },
        joinGroup(context, join) {
            return GroupServices.joinGroup(join).then(
                res => {
                    // console.log(join);
                    context.dispatch('group', res.data.id);
                    context.dispatch('account', null, {root: true}).then();
                    return Promise.resolve(res.data);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        joinRequest(context, join) {
            return GroupServices.joinRequest(join).then(
                res => {
                    context.dispatch('group', res.data.id).then();
                    return Promise.resolve(res.data);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        createGroup(context, group) {
            return GroupServices.createGroup(group).then(
                res => {
                    context.dispatch('account', null, {root: true}).then();
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            )

        },
        update(context, group){
            return GroupServices.update(group).then(
                res =>{
                    context.commit('copyGroup', res.data);
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        photo(context, group){
            context.commit('loading', true);
            return groupServices.takePhoto(group).then(
                res => {
                    context.commit('image',res.data.image);
                    const rootGroups = context.rootGetters['groups'];
                    rootGroups.forEach( rootGroup => {
                        if(rootGroup.id == group.id){
                            rootGroup.image = res.data.image;
                        }
                    });
                    context.commit('groups', rootGroups, {root: true});
                    context.commit('loading', false);
                    return Promise.resolve(res);
                },
                error => {
                    context.commit('loading', false);
                    return Promise.reject(error);
                }
            )
        }
    },
    mutations: {
        group(state, group) {
            // state.group = Object.assign( {},state.group, group);
            state.group = group;
        },
        groups(state, groups) {
            state.groups = groups;
        },
        image(state, image){
            state.group.image = image;
        },
        copyGroup(state, group) {
            state.group.name = group.name;
            state.group.city = group.city;
            state.group.state = group.state;
            state.group.description = group.description;
            state.group.type = group.type;
            state.group.privateGroup = group.privateGroup;
        },
    },
    getters: {
        group(state) {
            return state.group;
        },
        groups(state) {
            return state.groups;
        },

    }
}