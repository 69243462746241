
import {IonPage, IonContent, IonHeader, IonToolbar, IonItem, IonToggle, } from '@ionic/vue';
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
  name:'NotificationModal',
  props:{
    athlete: Object,
  },
  components:{
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonItem,
    IonToggle
  },
  setup(){
    return {
      v: useVuelidate(),
    }
  },
  methods: {

    save() {
      this.$emit("modal-save");
    },
    cancel() {
      this.$emit("modal-cancel");
    }
  },
  validations(){
    return{
      athlete :{
        dailyEmail: {
          required
        },
        weeklyEmail: {
          required
        },
        monthlyEmail: {
          required
        }
      }
    }
 }
}
