import ActivityServices from "@/services/activity.services";


export const activity = {
    namespaced: true,
    state(){
        return {
            activity:{},
            loading: false
        }
    },
    actions: {
        activity(context, activityId){
            context.commit('loading', true);
            return ActivityServices.getActivity(activityId).then(
            res =>{
                context.commit('activity',res);
                context.commit('loading', false);
                return Promise.resolve(res);
            }, error => {
                context.commit('loading', false);
                return Promise.reject(error);
                }
            )
        },
        update(context, activity){
            // context.commit('loading', true);
            return ActivityServices.updateActivity(activity).then(
                res => {
                    context.commit('details',res.data);
                    context.commit('loading', false);
                    return Promise.resolve(res);
                },
                error =>{
                    context.commit('loading', false);
                    return Promise.reject(error);
                }
            )
        },
        join(context, join){
            return ActivityServices.joinActivity(join).then(
                join => {
                    context.commit('activity',join);
                    return Promise.resolve(join);
                },
                error => {
                    return Promise.reject(error);
                }
            ).then(
                () =>{
                  void context.dispatch('account', {},{root: true});
                    return Promise.resolve();
                }
            )
        },
        createActivity(context, activity){
            return ActivityServices.createActivity(activity).then(
                res =>{
                   void context.dispatch('account',null, {root: true});
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        delete(context, activity){
            context.commit('loading', true);
            return ActivityServices.deleteActivity(activity).then(
                ()=> {
                    // context.commit('activity',{ name: 'deleted', details:{name:''}});
                    void context.dispatch('account',null, {root: true});
                    context.commit('loading', false);
                    return Promise.resolve();
                },
                 error => {
                    return Promise.reject(error);
                }
            )
        },
        photo(context, activity){
            context.commit('loading', true);
            console.log(activity);
            return ActivityServices.takePhoto(activity).then(
                res => {
                    context.commit('details',res.data);
                    context.commit('loading', false);
                    return Promise.resolve(res);
                },
                error => {
                    context.commit('loading', false);
                    return Promise.reject(error);
                }
            )
        }
    },
    mutations:{
        activity(state, activity){
            state.activity = activity;
        },
        details(state, details){
            state.activity.details = details;
        },
        loading(state, loading){
            state.loading = loading;
        },
        joined(state, joined){
            state.activity.joined = joined;
        }
    },
    getters:{
        activity(state){
            return state.activity;
        },
        details(_, getters){
            // const details = getters.activity.details;
            // details.startTime = dayjs(details.zonedDateTime).format('HH:mm');
            return getters.activity.details;
        },
        loading(state){
            return state.loading;
        },
        joinedActivity(_, getters){
            return getters.activity.joined;
        }


    }
}