import UserServices from "@/services/profile.services";

// const acct = JSON.parse(localStorage.getItem('user'));

export const account = {

    state(){
        return {
            account: {},
            loading: false
        };
    },
    actions:{
        account(context){
            context.commit('loading', true);
            return UserServices.getAccount().then(
                acct =>{
                    context.commit('account', acct);
                    context.commit('loading', false);
                    return Promise.resolve(acct);
                },
                error =>{
                    context.commit('loading', false);
                    return Promise.reject(error);
                }
            )
        },
    },
    mutations:{
        account(state, account){
            state.account= account;
        },
        loading(state, loading){
            state.loading = loading;
        },
        groups(state, groups){
            state.account.groups = groups;
        }
    },
    getters:{
        account(state){
            return state.account;
        },
        athlete(_, getters){
            return getters.account.athlete;
        },
        hasEvents(_, getters){
            return getters.upcomingEvents && getters.upcomingEvents.length > 0;
        },
        upcomingEvents(_, getters){
            return getters.account.upcomingEvents;
        },
        upcomingActivities(_, getters){
            return getters.account.upcomingActivities;
        },
        hasActivities(_, getters){
            return getters.upcomingActivities && getters.upcomingActivities.length > 0;
        },
        groups(_, getters){
            return getters.account.groups;
        },
        hasGroups(_, getters){
            return getters.groups !== undefined && getters.groups.length > 0;
        },
        loading(state){
            return state.loading;
        }
    }


};