import { Camera, CameraResultType } from '@capacitor/camera';


export function useCamera(){

    const takePicture = async () => {
        const image = await Camera.getPhoto({
            quality: 100,
            allowEditing: true,
            resultType: CameraResultType.Uri
        });

        const blob = await fetch(image.webPath).then(r => r.blob());
        //call upload
       return blob;
    };

    return {
      takePicture
    }

}
