
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { calendar, person, people, home } from 'ionicons/icons';
import theMenu from "@/components/theMenu.vue";

export default {
  name: 'Tabs',
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    theMenu,
  },
  setup() {
    return {
      calendar,
      person,
      people,
      home,
    }
  }
}
