import UserServices from "@/services/profile.services";
import profileService from "@/services/profile.services";

export const profile = {
    namespaced: true,
    state(){
        return{
            profile:{}
        };
    },
    actions:{
        profile(context, profileId){
            return UserServices.getProfile(profileId).then(
                profile =>{
                    context.commit('profile', profile);
                    return Promise.resolve(profile);
                },
                error =>{
                    return Promise.reject(error);
                }
            )
        },
        update(context, profile){
            return UserServices.updateAccount(profile).then(
                profile =>{
                    context.commit('profile', profile);
                    return Promise.resolve(profile);
                },
                error =>{
                    return Promise.reject(error);
                }
            ).then(
                () =>{
                    context.dispatch('account',null, {root: true});
                }
            )
        },

        takePhoto(context) {
            return profileService.takePhoto().then(
                profile => {
                    context.commit('profile', profile);
                    return Promise.resolve(profile);
                },
                cancel => {
                    return Promise.resolve(cancel);
                },
                error =>{
                    return Promise.reject(error);
                }
            ).then(
                () =>{
                    context.dispatch('account',null, {root: true});
                }
            )
        }
    },
    mutations:{
        profile(state, profile){
            state.profile = profile;
        },
    },
    getters:{
        profile(state){
            return state.profile;
        },
        hasProfile(_, getters){
            return getters.profile !== null
        },
        canEdit(state, getters, _, rootGetters){
            return state.profile.athlete.id == rootGetters.athlete.id;
        },
        hasEvents(_, getters){
            return getters.profile.upcomingEvents && getters.profile.upcomingEvents.length > 0;
        },
        hasActivities(_, getters){
            return getters.profile.upcomingActivities && getters.profile.upcomingActivities.length > 0;
        },
        hasGroups(_, getters){
            return getters.profile.groups === undefined || getters.profile.groups.length > 0;
        }

    }
}