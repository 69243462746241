import axios from 'axios';
import authHeader from "@/services/auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'authenticate', {
                username: user.username,
                password: user.password,
                rememberMe: true
            })
            .then(response => {
                if (response.data.id_token) {
                    localStorage.setItem('token', JSON.stringify(response.data.id_token));
                    return response.data.id_token;
                }
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }

    register(user) {
        return axios.post(API_URL + 'register', {
            login: user.email,
            email: user.email,
            password: user.password,
            firstName: user.firstName,
            lastName: user.lastName,
            langKey: 'en'
        });
    }

    reset(resetAccount){
        return  axios
             .post(API_URL+'account/reset-password/finish', {
                key: resetAccount.key, newPassword: resetAccount.newPassword });
    }

    requestReset(email){
        return  axios
            .post(API_URL+'account/reset-password/init', email,  {
                headers: {
                    'content-type': 'text/plain',
                }
            });
    }

    activate(key){
        console.log(key);
        return axios.get(API_URL+'activate?key='+key);

    }

    delete(user){
        return axios
            .delete(API_URL + 'account/delete', {
                headers: authHeader(),
                data: {
                    username: user.username,
                    password: user.password,
                    rememberMe: false
                }
            })
            .then(response => {
                if (response.status === 200) {
                    // this.logout();
                    return true;
                }
                return false;
            },
                error =>{
                return false;
                });
    }
}

export default new AuthService();