import { createStore } from "vuex";
import { auth } from "./auth.module";
import {account} from "./user.module";
import {event} from "./event.module";
import { group } from "./group.module";
import { activity} from "./activity.module";
import {profile} from "./profile.module";

const store = createStore({
    modules: {
        auth,
        account,
        event,
        group,
        activity,
        profile
    },
});

export default store;