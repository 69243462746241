
import { ref, computed, reactive } from 'vue';
import { IonContent, IonMenu, IonLabel, menuController, IonModal, IonList, IonHeader, IonTitle, IonItem,IonToolbar, IonItemDivider, alertController, IonAlert} from '@ionic/vue';
import NotificationModal from "@/components/modal/NotificationModal.vue";
import authService from "@/services/auth.service";
import {useStore} from "vuex";
import router from "@/router";
export default {
  name:'theMenu',

  components:{
    IonContent,
    IonMenu,
    IonModal,
    NotificationModal,
    IonList,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel,
    IonItemDivider,
    IonAlert
  },
  created() {
    this.loadAccount();
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(
          () => {
            menuController.close('main');
            this.$router.push("/login");
          }
      );
    },
    openNotificationModal() {
      this.modalIsOpen = true;
    },
    handleSave() {
      this.$store.dispatch('profile/update',{athlete: this.account});
      this.modalIsOpen = false;
    },
    handleCancel() {
      this.modalIsOpen = false;
    },
    deleteDone(){
      this.setOpen(false)
      if(!this.accountDlt.error){
        this.modalIsOpen = false;
        router.push('/login');
      }
    },

  },
  watch:{
    account(){
      if(this.account.logout)
        this.logout();
    }
  },
  setup(){
    const deleteResponse = ref(false);
    const accountDlt = reactive({
      message:'',
      error:false});
    const store = useStore();
    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => isOpenRef.value = state;

    function  loadAccount(){
      return store.dispatch('account');
    }

    const account =  computed( ()=>{
      const profile = store.getters.athlete;
      return profile? profile: {email:'',logout: true};
    });

    const presentAlert = async () => {
      const alert = await alertController.create({
        header: 'To delete your account input your password.',
        buttons: [ {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            deleteResponse.value = false;
          },
        },
          {
            text: 'OK',
            role: 'confirm',
            handler: () => {
              deleteResponse.value = true;
            },
          },],
        inputs: [
          {
            type: 'password',
            placeholder: 'password',
          },
        ],
      });

      await alert.present();
      const result = await alert.onDidDismiss();
      if(deleteResponse.value){
       const isDeleted = await authService.delete({username: account.value.username, password:result.data.values[0]});
        if(isDeleted){
          accountDlt.message= 'Your account has been deleted.';
          accountDlt.error= false;
          store.dispatch('auth/logout');
        }else {
          accountDlt.message= 'Credentials did not match.';
          accountDlt.error= true;
        }
        setOpen(true);
      }
    };
    return { presentAlert, loadAccount, account, isOpenRef, setOpen, accountDlt };
  },
  data(){
    return {
      modalIsOpen: false,
    }
  }
}
