import axios from 'axios';
import authHeader from "@/services/auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class EventServices {

    getEvent(id){
        return axios.get( API_URL+'events/'+id, {headers: authHeader()})
            .then( res => {
                return res.data;
            })
            .catch( err => console.log(err));
    }

    getAllEvent(){
        return axios.get( API_URL+'events/future', {headers: authHeader()})
            .then( res => {
                console.log(res);
                return res.data;
            })
            .catch( err => {
                    console.log('this error:');
                console.log(err);

            });

    }

    joinEvent(join){

        return axios.post(API_URL+'events/participants', join,
            {headers: authHeader()})
            .then( res =>{
                return res.data;
            }).catch(err => console.log(err));
    }

}
export default  new EventServices();